<template>
  <div id="investmentpreference">
    
  </div>
</template>

<script>
export default {
  name:'InvestmentPreference',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>

</style>
